<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    PreviewHeader
    div(v-if="showRecord").wrap-preview.wrap-preview-record.pt50
      ModuleSetRecord(v-if="'karte' === recordType")
      ModuleSetConsultation(v-if="'consultation' === recordType")
      ModuleSetInbody(v-if="'inbody' === recordType")
    div(v-else-if="password").wrap-preview.f.fh
      div.wrap-pw-input.f.fm
        v-text-field(
          label="パスワードを入力"
          v-model="inputedPassword"
          :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="showPw ? 'text' : 'password'"
          @click:append="showPw = !showPw"
          @keydown.enter="checkPw").mr8
        span(@click="checkPw" :class="{'is-active': enterIsActive}").button-submit Enter
    div(v-else).wrap-preview.f.fh
      span 共有設定がされていません。

</template>

<style lang="scss" scoped>
.wrap-preview {
  width: 100%;
  min-height: 100vh;
  .wrap-pw-input {
    width: 200px;
    .button-submit {
      font-size: 16px;
      color: #999;
      font-weight: 500;
      &.is-active {
        color: #2196f3;
      }
    }
  }
}

.wrap-preview-record {
  pointer-events: none;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import PreviewHeader from '@/components/shared/PreviewHeader'
import ModuleSetRecord from '@/components/module/ModuleSetRecord'
import ModuleSetConsultation from '@/components/module/ModuleSetConsultation'
import ModuleSetInbody from '@/components/module/ModuleSetInbody'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const { mapState: mapStateRecord, mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
    Auth,
    PreviewHeader,
    ModuleSetRecord,
    ModuleSetConsultation,
    ModuleSetInbody
  },
  data () {
    return {
      recordType: null,
      record: null,
      showRecord: false,
      password: null,
      inputedPassword: "",
      showPw: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || '4文字以上'
      },
      enterIsActive: false
    }
  },
  watch: {
    inputedPassword: function (newVal) {
      this.enterIsActive = (newVal.length >= 4)? true : false
    }
  },
  async created () {
    this.recordType = this.$route.params.recordType
    this.record = await this.getRecordById(this.$route.params.recordId)
    this.password = this.record.password
    await this.loadRecords(this.$route.params.clientId)
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    ...mapActionsRecord([
      'loadRecords',
      'getRecordById'
    ]),
    onFailedAuthentication () {
      // this.$router.push('/sign-in')
    },
    checkPw () {
      if (this.password === this.inputedPassword) {
        this.showRecord = true
      } else {
        alert("パスワードが異なります。")
      }
    }
  }
}
</script>
