<template lang="pug">
  div.wrap-header
    div.header-content
      div.left.f.fm
        div.wrap-logo.f.fm
          img(src="/img/logo.png")
          span.logo-text DEPORTARE CLUB

</template>

<style lang="scss" scoped>
.wrap-header {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background: #2a2a2a;
  .header-content {
    position: relative;
    width: 93%;
    max-width: 820px;
    height: 100%;
    margin: 0 auto;
    .left {
      position: absolute;
      left: 0;
      height: 100%;
      .wrap-logo {
        img {
          height: 32px;
        }
        .logo-text {
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .right {
      position: absolute;
      right: 0;
      height: 100%;
    }
  }
}
</style>

<script>

export default {
  components: {
  },
  data () {
    return {

    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
